import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import { TableContainer } from "@/game-deadlock/HeroMatchups.style.jsx";
import { Content } from "@/game-deadlock/HeroOverview.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function HeroMatchups() {
  const { t } = useTranslation();
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const { loading: _heroesListLoading, dict: heroesDict } = useHeroesList();
  const {
    loading: heroStatsLoading,
    stats: heroStats,
    heroName,
  } = useHeroStats(heroIdOrName);

  return (
    <PageContainer>
      <HeroPageHeader
        heroIdOrName={heroIdOrName}
        stats={heroStats}
        titleSuffix={t("", "Lane matchups stats")}
      />
      <section className={Content()}>
        <div className="main-col">
          {heroStatsLoading ? (
            <DataTableLoading tallRows loadingRows={15} />
          ) : (
            <DataTable
              className={TableContainer()}
              tallRows
              indexCol
              sortCol={1} // win rate
              cols={[
                {
                  align: "left",
                  display: t("deadlock:common.laneOpponent", "Lane Opponent"),
                  className: "hero-meta",
                },
                {
                  display: t(
                    "deadlock:stats.heroNameWinRate",
                    "{{heroName}} Win Rate",
                    { heroName },
                  ),
                  className: "winrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.wrImprovement", "WR Improvement"),
                  className: "winrate-improvement",
                  isStat: true,
                },
                {
                  display: t("common:stats.matchupRate", "Matchup Rate"),
                  className: "matchup-rate",
                  isStat: true,
                },
                {
                  display: t("common:stats.matches", "Matches"),
                  className: "matches",
                  isStat: true,
                },
              ]}
              rows={[
                ...(heroStats.lane_strong_against_heroes || []),
                ...(heroStats.lane_weak_against_heroes || []),
              ]
                .filter((matchup) => matchup.num_matches)
                .map((matchup) => {
                  const hero = heroesDict[matchup.lane_enemy_hero];
                  const wrPrefix = matchup.win_rate_diff > 0 ? "+" : "";
                  return [
                    {
                      display: (
                        <div className="hero-meta">
                          <div className="hero-img">
                            <img
                              src={
                                hero.heroId &&
                                Assets.getHeroPortrait(hero.heroId)
                              }
                              width="28"
                              height="38"
                              alt={`${hero.heroName} Deadlock Hero`}
                            />
                          </div>
                          <span className="shade0">{hero.heroName}</span>
                        </div>
                      ),
                      value: matchup.lane_enemy_hero,
                    },
                    {
                      display: localeString(matchup.win_rate, true, 1),
                      value: matchup.win_rate,
                    },
                    {
                      display: `${wrPrefix}${localeString(matchup.win_rate_diff, true, 1)}`,
                      value: matchup.win_rate_diff,
                    },
                    {
                      display: localeString(matchup.pick_rate, true, 1),
                      value: matchup.pick_rate,
                    },
                    {
                      display: localeString(
                        matchup.num_player_matches,
                        false,
                        0,
                      ),
                      value: matchup.num_player_matches,
                    },
                  ];
                })}
            />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} tab="matchups" />
        </aside>
      </section>
    </PageContainer>
  );
}

export default HeroMatchups;

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
